<template>
	<div class="classification_box">
		<div class="navBar">
			<div class="navBar_main">
				<div class="navBar_main_fl flex-center">
					<div
						class="menuBar display pointer"
						@mouseover="mouseOver"
						@mouseleave="mouseLeave"
					>
						<img class="icon_class" src="../assets/img/class.png" alt="" />
						全部商品分类
						<div class="sidebarBox" @mouseleave="childMouseLeave">
							<div class="sidebar" v-show="isShow == true">
								<div
									class="list flex-center pointer"
									:class="sidebarActive == item.id ? 'sidebarActive' : ''"
									v-for="(item, index) in $store.state.sidebarData"
									:key="index"
									@click="sidebarBtn(item)"
									@mouseover="childMouseOver(item)"
								>
									<img class="icon_img" :src="item.url" alt="" />
									<div class="name">{{ item.label }}</div>
								</div>
								<div class="childBox" v-show="isChildShow == true">
									<div class="title" @click="toDetails(1, titleId, titleName)">
										{{ titleName }}
									</div>
									<div
										class="child_list flex"
										v-for="(item, index) in goodsClassification"
										:key="index"
									>
										<div class="label Between">
											<span
												@click="
													toDetails(2, item.id, titleName + '>' + item.label)
												"
												>{{ item.label }}</span
											>
											<span>
												<img
													class="arrows"
													src="@/assets/img/arrows.png"
													alt=""
												/>
											</span>
										</div>
										<div class="child_list_list flex flex-wrap">
											<div
												class="child_list_list_div"
												@click="
													toDetails(
														3,
														items.id,
														titleName + '>' + item.label + '>' + items.label,
														item.id
													)
												"
												v-for="(items, indexs) in item.children"
												:key="indexs"
											>
												{{ items.label }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="navList flex-center">
						<div v-if="isId != 0" class="flex-center">
							<div
								class="navName flex-center pointer"
								:class="active == item.id ? 'active' : ''"
								v-for="(item, index) in navData"
								:key="index"
								@click="navBtn(item, index)"
							>
								<img
									v-if="item.hot == 1"
									class="hot"
									src="../assets/img/hot.png"
									alt=""
								/>
								<img
									v-if="item.hot == 2"
									class="stars"
									src="../assets/img/stars.png"
									alt=""
								/>
								{{ item.name }}

								<div
									class="line"
									v-if="isId != 0 ? active == item.id : active == index"
								></div>
							</div>
						</div>
						<div v-else class="flex-center">
							<div
								class="navName flex-center pointer"
								:class="active == index ? 'active' : ''"
								v-for="(item, index) in navData"
								:key="index"
								@click="navBtn(item, index)"
							>
								<img
									v-if="item.hot == 1"
									class="hot"
									src="../assets/img/hot.png"
									alt=""
								/>
								<img
									v-if="item.hot == 2"
									class="stars"
									src="../assets/img/stars.png"
									alt=""
								/>
								{{ item.name }}

								<div
									class="line"
									v-if="isId != 0 ? active == item.id : active == index"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		navData: {
			type: Array,
		},
		sidebarData: {
			type: Array,
		},
		types: {
			type: Number,
		},
	},
	data() {
		return {
			active: 0,
			titleName: '',
			titleId: '',
			goodsClassification: [],
			sidebarActive: null,
			isShow: true,
			isChildShow: false,
			isHome: '',
			isId: 0,
		}
	},
	created() {
		this.$router.beforeEach((to, from, next) => {
			this.isHome = to.path
			if (to.path == '/homes/index') {
				this.isShow = true
			} else {
				this.isShow = false
			}
			next()
		})
	},

	mounted() {
		// if(this.types != 1){
		//         this.active = sessionStorage.getItem('navIndex')
		//         if(this.active == null){
		//             if(this.$route.query.type){
		//                 this.isId = this.$route.query.type
		//                 this.active = this.$route.query.type
		//             }else{
		//                 this.active = 0
		//             }
		//         }

		//     }else{
		//          this.active = sessionStorage.getItem('navIndex')
		//          if(this.active == null){
		//              this.active = 0
		//          }else{
		//               if(this.$route.query.type){
		//                 this.isId = this.$route.query.type
		//                 this.active = this.$route.query.type
		//             }else{
		//                 this.active = 0
		//             }
		//          }
		//     }

		this.active = sessionStorage.getItem('navIndex')
		if (this.active == null) {
			if (this.$route.query.type != 2 && this.$route.query.type != 3) {
				this.isId = this.$route.query.type
				this.active = this.$route.query.type
			} else {
				this.active = 0
			}
		}
		let url = window.location.href.split('/homes')
		this.isHome = '/homes' + url[1]
		if (this.isHome == '/homes/index') {
			this.isShow = true
		} else {
			this.isShow = false
		}
	},
	beforeDestroy() {
		//离开页面清除数据
		sessionStorage.removeItem('navIndex')
	},
	methods: {
		//点击tab分类
		navBtn(item, index) {
			this.isId = 0
			if (this.types == 1) {
				let routeData = {}
				if (item.name == '火热推荐') {
					routeData = this.$router.resolve({
						path: item.url,
						query: {
							type: item.id,
							id: '',
							catGrade: 1,
							goodsGrade: 1,
							navName: '火热推荐',
						},
					})
				} else {
					routeData = this.$router.resolve({
						path: item.url,
						query: {
							type: item.id,
						},
					})
				}
				window.open(routeData.href, '_blank')
				sessionStorage.removeItem('serveData')
				localStorage.removeItem('gradeData')
			} else {
				sessionStorage.setItem('navIndex', index)
				this.$emit('navBtn', item)
			}
			this.active = index
		},
		//点击商品分类
		sidebarBtn(item) {
			this.sidebarActive = item.id
		},
		//移入
		mouseOver() {
			if (this.isHome != '/homes/index') {
				this.isShow = true
			}
		},
		// 移出
		mouseLeave() {
			if (this.isHome != '/homes/index') {
				this.isShow = false
			}
		},

		//子级移入
		childMouseOver(item) {
			this.isChildShow = true
			this.titleName = item.label
			this.titleId = item.id
			this.goodsClassification = item.children
		},
		childMouseLeave() {
			this.isChildShow = false
		},
		//去商品列表
		toDetails(catGrade, id, name, parentId) {
			let navName = name
			let goodsGrade = null
			let gradeData = {
				grades: this.titleId,
				gradess: null,
				gradesss: null,
			}
			if (catGrade == 1) {
				goodsGrade = 2
			} else if (catGrade == 2) {
				gradeData.gradess = id
				goodsGrade = 3
			} else if (catGrade == 3) {
				gradeData.gradess = parentId
				gradeData.gradesss = id
				goodsGrade = 3
			}
			localStorage.setItem('gradeData', JSON.stringify(gradeData))
			let routeData = this.$router.resolve({
				path: '/mall/goodsList',
				query: {
					id: id,
					catGrade: catGrade,
					goodsGrade: goodsGrade,
					navName: navName,
				},
			})
			window.open(routeData.href, '_blank')
		},
	},
}
</script>
<style lang="scss" scoped>
.classification_box {
	.navBar {
		min-width: 1200px;
		background: white;
		margin: 0 auto;
		.navBar_main {
			width: 1200px;
			height: 40px;
			margin: 0 auto;

			.menuBar {
				width: 232px;
				height: 40px;
				background: #e62129;
				border-radius: 4px 4px 1px 1px;
				font-size: 18px;
				color: #ffffff;
				position: relative;

				.icon_class {
					width: 25px;
					height: 18px;
					margin-right: 16px;
					margin-top: 2px;
				}
				.sidebarBox {
					position: absolute;
					top: 40px;
					left: 0;
					z-index: 999;
					.sidebar {
						width: 232px;
						height: 460px;
						padding: 12px 0px;
						box-sizing: border-box;
						background: white;
						overflow: hidden;
						overflow-y: scroll;
						.list {
							width: 100%;
							height: 44px;
							font-size: 14px;
							color: #333333;
							padding: 0px 20px;
							.icon_img {
								width: 17px;
								height: 17px;
								margin-right: 12px;
							}
						}
						.list:hover {
							background: #ededed;
						}
						.sidebarActive {
							background: #ededed;
						}
					}
					.childBox::-webkit-scrollbar {
						/*隐藏滚轮*/
						display: none;
					}
					.childBox {
						width: 734px;
						height: 460px;
						background: white;
						position: absolute;
						top: 0px;
						right: -734px;
						z-index: 9999;
						box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
						padding: 28px 37px;
						box-sizing: border-box;
						border-left: 1px solid #ccc;
						overflow: hidden;
						overflow-y: scroll;
						.title {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #e62129;
						}
						.child_list {
							margin-top: 20px;
							.label {
								min-width: 101px;
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #333;
								margin-right: 12px;
							}
							.label:hover {
								color: #e62129;
							}
							.child_list_list {
								.child_list_list_div {
									font-size: 14px;
									color: #666;
									margin-right: 20px;
								}
								.child_list_list_div:hover {
									color: #e62129;
								}
							}
						}
					}
				}
			}
		}
		.navList {
			.active {
				color: #e1251b !important;
			}
			.navName {
				font-size: 16px;
				color: #333333;
				margin-left: 47px;
				margin-right: 11px;
				position: relative;
				.line {
					width: 100%;
					height: 2px;
					background: #e1251b;
					position: absolute;
					left: 0;
					bottom: -9px;
				}

				.stars {
					width: 14px;
					height: 17px;
					margin-right: 9px;
				}
				.hot {
					width: 16px;
					height: 17px;
					margin-left: -8px;
					margin-right: 9px;
				}
			}
		}
	}
	.arrows {
		//    width: 8px;
		//    height: 12px;
	}
}
</style>